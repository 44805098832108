(function () {
    'use strict';

    var module = angular.module('bf.services.systemsettings', []);

    module.service('systemSettingsService', [
        '$q', '_', 'constants', 'dataStoreService', 'ENVIRONMENTAL', function ($q, _, constants, dataStoreService, ENVIRONMENTAL) {
            var apiSection = "/systemsettings/";

            var systemSettingsCache = {};

            function localGetFromCache(settingName) {
                if (_.has(systemSettingsCache, settingName)) {
                    return _.property(settingName)(systemSettingsCache);
                } else {
                    return undefined;
                }
            }

            function localGetFromServer(settingName) {
                var deffered = $q.defer();
                if (_.isUndefined(settingName) || settingName === '') {
                    return deffered.reject();
                }
                dataStoreService.getResource(ENVIRONMENTAL.BASEURLS.API + apiSection + encodeURIComponent(settingName)).then(function(result) {
                    deffered.resolve(result);
                });
                return deffered.promise;
            }

            function localAddToOrReplaceCache(settingName, value) {
                if (_.has(systemSettingsCache, settingName)) {
                    systemSettingsCache = _.omit(systemSettingsCache, settingName);
                }
                systemSettingsCache[settingName] = value;
            }

            function localGet(settingName) {
                var deffered = $q.defer();
                var cachedSetting = localGetFromCache(settingName);
                if (_.isUndefined(cachedSetting)) {
                    localGetFromServer(settingName).then(function(result) {
                        if (!_.isUndefined(result) && !_.isNull(result)) {
                            localAddToOrReplaceCache(settingName, result);
                        }
                        deffered.resolve(result);
                    });
                } else {
                    deffered.resolve(cachedSetting);
                }
                return deffered.promise;
            }

            function localGetPreviewableFileExtensions() {
                return localGet(constants.SYSTEM.SETTINGS.PREVIEWABLEFILEEXTENSIONS);
            }
            
            function localCacheCommonData() {
                localGet(constants.SYSTEM.SETTINGS.PREVIEWABLEFILEEXTENSIONS);
            }

            function localClearCachedData() {
                systemSettingsCache = {};
            }

            return {
                getPreviewableFileExtensions: localGetPreviewableFileExtensions,
                cacheCommonData: localCacheCommonData,
                clearCachedData: localClearCachedData
            }
        }
    ]).run([
            '_', 'systemSettingsService', 'events', 'constants', function (_, systemSettingsService, events, constants) {
                events.on(constants.SECURITY.EVENTS.LOGGEDIN, function (evt, userProfile) {
                systemSettingsService.cacheCommonData();
            });
                events.on(constants.SECURITY.EVENTS.LOGGEDOUT, function (evt, data) {
                systemSettingsService.clearCachedData();
            });
        }
    ]);
})();