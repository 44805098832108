(function () {
    "use strict";
    var module = angular.module("bf.services.log", []);

    module.service("logService", [
        "ENVIRONMENTAL", "$http", function (ENVIRONMENTAL, $http) {

            var apiSection = "/log/";

            function sendError(exception, message, details) {
                sendLog(exception, message, details, "error");
            }

            function sendWarning(exception, message, details) {
                sendLog(exception, message, details, "warning");
            }

            function sendInformation(message, details) {
                sendLog(null, message, details, "information");
            }

            function sendDebug(message, details) {
                sendLog(null, message, details, "debug");
            }

            function sendLog(exception, message, details, logLevel) {
                try {
                    if (exception) {
                        details = details || {};
                        details.Exception = exception;
                    }

                    var payload = {
                        LogKey: ENVIRONMENTAL.SETTINGS.LOGKEY,
                        Message: message,
                        DetailsJson: details ? JSON.stringify(details) : null
                    };

                    $http.post(ENVIRONMENTAL.BASEURLS.API + apiSection + logLevel, JSON.stringify(payload));
                } catch (logException) {
                    console.log("Could not log " + logLevel + " because: " + logException);
                }
            }

            return {
                error: sendError,
                warning: sendWarning,
                information: sendInformation,
                debug: sendDebug
            };
        }
    ]);
})();