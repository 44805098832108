const unsubstitutedVariable = /^#\{.*\}$/;

export const substitutedVariableOrDefault = (value, defaultValue) => {
    if (unsubstitutedVariable.test(value)) {
        return defaultValue;
    }

    return value;
};

