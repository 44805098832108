var assert_required   = require('./assert_required');
var base64_url_decode = require('./base64_url_decode');

// var qs                = require('qs');
// var xtend             = require('xtend');
// var trim              = require('trim');
// var reqwest           = require('reqwest');
// var WinChan           = require('winchan');

//var jsonp             = require('jsonp');
var jsonpOpts         = { param: 'cbx', timeout: 8000, prefix: '__auth0jp' };
1
var use_jsonp         = require('./use_jsonp');
var LoginError        = require('./LoginError');
var json_parse        = require('./json_parse');

/**
 * `Array.isArray` Polyfill for IE9
 * source: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/isArray
 */

if(!Array.isArray) {
  Array.isArray = function(arg) {
    return Object.prototype.toString.call(arg) === '[object Array]';
  };
}

/**
 * Create an `Auth0` instance with `options`
 *
 * @class Auth0
 */

function Auth0 (options) {
  if (!(this instanceof Auth0)) {
    return new Auth0(options);
  }

  assert_required(options, 'clientID');
  assert_required(options, 'domain');

  this._useJSONP = options.forceJSONP || use_jsonp();
  this._clientID = options.clientID;
  this._callbackURL = options.callbackURL || document.location.href;
  this._domain = options.domain;
  this._callbackOnLocationHash = false || options.callbackOnLocationHash;
}

/**
 * Export version with `Auth0` constructor
 *
 * @property {String} version
 */

//Auth0.version = require('package.version');

/**
 * Redirect current location to `url`
 *
 * @param {String} url
 * @api private
 */

Auth0.prototype._redirect = function (url) {
  global.window.location = url;
};

/**
 * Renders and submits a WSFed form
 *
 * @param {Object} options
 * @param {Function} formHtml
 * @api private
 */

Auth0.prototype._renderAndSubmitWSFedForm = function (options, formHtml) {
  var div = document.createElement('div');
  div.innerHTML = formHtml;
  var form = document.body.appendChild(div).children[0];

  if (options.popup && !this._callbackOnLocationHash) {
    form.target = 'auth0_signup_popup';
  }

  form.submit();
};

/**
 * Resolve response type as `token` or `code`
 *
 * @return {Object} `scope` and `response_type` properties
 * @api private
 */

Auth0.prototype._getMode = function () {
  return {
    scope: 'openid',
    response_type: this._callbackOnLocationHash ? 'token' : 'code'
  };
};

/**
 * Get user information from API
 *
 * @param {Object} profile
 * @param {String} id_token
 * @param {Function} callback
 * @api private
 */

Auth0.prototype._getUserInfo = function (profile, id_token, callback) {
alert('getuserinfo');
  if (profile && !profile.user_id) { // the scope was just openid
    var self = this;
    var url = 'https://' + self._domain + '/tokeninfo?';
    var fail = function (status, description) {
      var error = new Error(status + ': ' + (description || ''));

      // These two properties are added for compatibility with old versions (no Error instance was returned)
      error.error = status;
      error.error_description = description;

      callback(error);
    };

    if (this._useJSONP) {
      return jsonp(url + qs.stringify({id_token: id_token}), jsonpOpts, function (err, resp) {
        if (err) {
          return fail(0, err.toString());
        }

        return resp.status === 200 ?
          callback(null, resp.user) :
          fail(resp.status, resp.error);
      });
    }

    return reqwest({
      url:          url,
      method:       'post',
      type:         'json',
      crossOrigin:  true,
      data:         {id_token: id_token}
    }).fail(function (err) {
      fail(err.status, err.responseText);
    }).then(function (userinfo) {
      callback(null, userinfo);
    });
  }

  callback(null, profile);
};

/**
 * Get profile data by `id_token`
 *
 * @param {String} id_token
 * @param {Function} callback
 * @api public
 */

Auth0.prototype.getProfile = function (id_token, callback) {
  if (!id_token || typeof id_token !== 'string') {
    return callback(new Error('Invalid token'));
  }

  this._getUserInfo(this.decodeJwt(id_token), id_token, callback);
};

/**
 * Validate a user
 *
 * @param {Object} options
 * @param {Function} callback
 * @api public
 */

Auth0.prototype.validateUser = function (options, callback) {
  var endpoint = 'https://' + this._domain + '/public/api/users/validate_userpassword';
  var query = xtend(
    options,
    {
      client_id:    this._clientID,
      username:     trim(options.username || options.email || '')
    });

  if (this._useJSONP) {
    return jsonp(endpoint + '?' + qs.stringify(query), jsonpOpts, function (err, resp) {
      if (err) {
        return callback(err);
      }
      if('error' in resp && resp.status !== 404) {
        return callback(new Error(resp.error));
      }
      callback(null, resp.status === 200);
    });
  }

  reqwest({
    url:     endpoint,
    method:  'post',
    type:    'text',
    data:    query,
    crossOrigin: true,
    error: function (err) {
      if (err.status !== 404) { return callback(new Error(err.responseText)); }
      callback(null, false);
    },
    success: function (resp) {
      callback(null, resp.status === 200);
    }
  });
};

/**
 * Decode Json Web Token
 *
 * @param {String} jwt
 * @api public
 */

Auth0.prototype.decodeJwt = function (jwt) {
  var encoded = jwt && jwt.split('.')[1];
  return json_parse(base64_url_decode(encoded));
};

/**
 * Given the hash (or a query) of an URL returns a dictionary with only relevant
 * authentication information. If succeeds it will return the following fields:
 * `profile`, `id_token`, `access_token` and `state`. In case of error, it will
 * return `error` and `error_description`.
 *
 * @method parseHash
 * @param {String} hash URL to be parsed
 * @example
 *      var auth0 = new Auth0({...});
 *
 *      // Returns {profile: {** decoded id token **}, state: "good"}
 *      auth0.parseHash('#id_token=.....&state=good&foo=bar');
 *
 *      // Returns {error: "invalid_credentials", error_description: undefined}
 *      auth0.parseHash('#error=invalid_credentials');
 *
 *      // Returns {error: "invalid_credentials", error_description: undefined}
 *      auth0.parseHash('?error=invalid_credentials');
 *
 * @api public
 */

Auth0.prototype.parseHash = function (hash) {
  if (hash.match(/error/)) {
    hash = hash.substr(1).replace(/^\//, '');
    var parsed_qs = qs.parse(hash);
    var err = {
      error: parsed_qs.error,
      error_description: parsed_qs.error_description
    };
    return err;
  }
  if(!hash.match(/access_token/)) {
    // Invalid hash URL
    return null;
  }
  hash = hash.substr(1).replace(/^\//, '');
  var parsed_qs = qs.parse(hash);
  var id_token = parsed_qs.id_token;
  var prof = this.decodeJwt(id_token);
  var invalidJwt = function (error) {
    var err = {
      error: 'invalid_token',
      error_description: error
    };
    return err;
  };

  // aud should be the clientID
  if (prof.aud !== this._clientID) {
    return invalidJwt(
      'The clientID configured (' + this._clientID + ') does not match with the clientID set in the token (' + prof.aud + ').');
  }

  // iss should be the Auth0 domain (i.e.: https://contoso.auth0.com/)
  if (prof.iss && prof.iss !== 'https://' + this._domain + '/') {
    return invalidJwt(
      'The domain configured (https://' + this._domain + '/) does not match with the domain set in the token (' + prof.iss + ').');
  }

  return {
    profile: prof,
    id_token: id_token,
    access_token: parsed_qs.access_token,
    state: parsed_qs.state
  };
};

/**
 * Signup
 *
 * @param {Object} options
 * @param {Function} callback
 * @api public
 */

Auth0.prototype.signup = function (options, callback) {
  var self = this;

  var query = xtend(
    this._getMode(),
    options,
    {
      client_id: this._clientID,
      redirect_uri: this._callbackURL,
      email: trim(options.username || options.email || ''),
      tenant: this._domain.split('.')[0]
    });

  function success () {
    if ('auto_login' in options && !options.auto_login) {
      if (callback) callback();
      return;
    }
    self.login(options, callback);
  }

  function fail (status, resp) {
    var error = new LoginError(status, resp);
    if (callback) return callback(error);
    throw error;
  }

  if (this._useJSONP) {
    return jsonp('https://' + this._domain + '/dbconnections/signup?' + qs.stringify(query), jsonpOpts, function (err, resp) {
      if (err) {
        return fail(0, err);
      }
      return resp.status == 200 ?
              success() :
              fail(resp.status, resp.err);
    });
  }

  reqwest({
    url:     'https://' + this._domain + '/dbconnections/signup',
    method:  'post',
    type:    'html',
    data:    query,
    success: success,
    crossOrigin: true
  }).fail(function (err) {
    fail(err.status, err.responseText);
  });
};

/**
 * Change password
 *
 * @param {Object} options
 * @param {Function} callback
 * @api public
 */

Auth0.prototype.changePassword = function (options, callback) {
  var self = this;
  var query = {
    tenant:         this._domain.split('.')[0],
    client_id:      this._clientID,
    connection:     options.connection,
    email:          trim(options.username || options.email || ''),
    password:       options.password
  };


  function fail (status, resp) {
    var error = new LoginError(status, resp);
    if (callback)      return callback(error);
  }

  if (this._useJSONP) {
    return jsonp('https://' + this._domain + '/dbconnections/change_password?' + qs.stringify(query), jsonpOpts, function (err, resp) {
      if (err) {
        return fail(0, err);
      }
      return resp.status == 200 ?
              callback(null, resp.message) :
              fail(resp.status, resp.err);
    });
  }

  reqwest({
    url:     'https://' + this._domain + '/dbconnections/change_password',
    method:  'post',
    type:    'html',
    data:    query,
    crossOrigin: true
  }).fail(function (err) {
    fail(err.status, err.responseText);
  }).then(function (r) {
    callback(null, r);
  });
};

/**
 * Builds query string to be passed to /authorize based on dict key and values.
 *
 * @method _buildAuthorizeQueryString
 * @param {Array} args
 * @param {Array} blacklist
 * @api private
 */

Auth0.prototype._buildAuthorizeQueryString = function (args, blacklist) {
  var query = xtend.apply(null, args);

  // Elements to filter from query string
  blacklist = blacklist || ['popup', 'popupOptions'];

  var i, key;

  for (i = 0; i < blacklist.length; i++) {
    key = blacklist[i];
    delete query[key];
  }

  if (query.connection_scope && Array.isArray(query.connection_scope)){
    query.connection_scope = query.connection_scope.join(',');
  }

  return qs.stringify(query);
};

/**
 * Login user
 *
 * @param {Object} options
 * @param {Function} callback
 * @api public
 */

Auth0.prototype.login = Auth0.prototype.signin = function (options, callback) {
  if (typeof options.username !== 'undefined' ||
      typeof options.email !== 'undefined') {
    return this.loginWithUsernamePassword(options, callback);
  }

  if (!!window.cordova) {
    return this.loginPhonegap(options, callback);
  }

  if (!!options.popup) {
    return this.loginWithPopup(options, callback);
  }

  var query = this._buildAuthorizeQueryString([
    this._getMode(),
    options,
    { client_id: this._clientID, redirect_uri: this._callbackURL }]
  );

  this._redirect('https://' + this._domain + '/authorize?' + query);
};

/**
 * Compute `options.width` and `options.height` for the popup to
 * open and return and extended object with optimal `top` and `left`
 * position arguments for the popup windows
 *
 * @param {Object} options
 * @api private
 */

Auth0.prototype._computePopupPosition = function (options) {
  var width = options.width;
  var height = options.height;

  var screenX = typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft;
  var screenY = typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop;
  var outerWidth = typeof window.outerWidth !== 'undefined' ? window.outerWidth : document.body.clientWidth;
  var outerHeight = typeof window.outerHeight !== 'undefined' ? window.outerHeight : (document.body.clientHeight - 22);
  // XXX: what is the 22?

  // Use `outerWidth - width` and `outerHeight - height` for help in
  // positioning the popup centered relative to the current window
  var left = screenX + (outerWidth - width) / 2;
  var top = screenY + (outerHeight - height) / 2;

  return { width: width, height: height, left: left, top: top };
};

/**
 * loginPhonegap method is triggered when !!window.cordova is true.
 *
 * @method loginPhonegap
 * @private
 * @param {Object}    options   Login options.
 * @param {Function}  callback  To be called after login happened. Callback arguments
 *                              should be:
 *                              function (err, profile, idToken, accessToken, state)
 *
 * @example
 *      var auth0 = new Auth0({ clientId: '...', domain: '...'});
 *
 *      auth0.signin({}, function (err, profile, idToken, accessToken, state) {
 *        if (err) {
 *         alert(err);
 *         return;
 *        }
 *
 *        alert('Welcome ' + profile.name);
 *      });
 */

Auth0.prototype.loginPhonegap = function (options, callback) {
  var mobileCallbackURL = 'https://' + this._domain + '/mobile';
  var self = this;
  var query = this._buildAuthorizeQueryString([
    this._getMode(),
    options,
    { client_id: this._clientID, redirect_uri: mobileCallbackURL}]);

    var popupUrl = 'https://' + this._domain + '/authorize?' + query;

    var ref = window.open(popupUrl, '_blank', 'location=yes');
    var answered = false;

    function errorHandler(event) {
      if (answered) { return; }
      callback(new Error(event.message), null, null, null, null);
      answered = true;
      return ref.close();
    }

    function startHandler(event) {
      if (answered) { return; }

      if ( event.url && !(event.url.indexOf(mobileCallbackURL + '#') === 0 ||
                         event.url.indexOf(mobileCallbackURL + '?') === 0)) { return; }

      var result = self.parseHash(event.url.slice(mobileCallbackURL.length));

      if (!result) {
        callback(new Error('Error parsing hash'), null, null, null, null);
        answered = true;
        return ref.close();
      }

      if (result.id_token) {
        self.getProfile(result.id_token, function (err, profile) {
          callback(err, profile, result.id_token, result.access_token, result.state);
          return ref.close();
        });
        answered = true;
        return;
      }

      // Case where we've found an error
      callback(new Error(result.err || result.error || 'Something went wrong'), null, null, null, null);
      answered = true;
      return ref.close();
    }

    function exitHandler() {
      if (answered) { return; }

      callback(new Error('Browser window closed'), null, null, null, null);

      ref.removeEventListener('loaderror', errorHandler);
      ref.removeEventListener('loadstart', startHandler);
      ref.removeEventListener('exit', exitHandler);
    }

    ref.addEventListener('loaderror', errorHandler);
    ref.addEventListener('loadstart', startHandler);
    ref.addEventListener('exit', exitHandler);

};

/**
 * loginWithPopup method is triggered when login method receives a {popup: true} in
 * the login options.
 *
 * @method loginWithPopup
 * @param {Object}   options    Login options.
 * @param {function} callback   To be called after login happened (whether
 *                              success or failure). This parameter is mandatory when
 *                              option callbackOnLocationHash is truthy but should not
 *                              be used when falsy.
 * @example
 *       var auth0 = new Auth0({ clientId: '...', domain: '...', callbackOnLocationHash: true });
 *
 *       // Error! No callback
 *       auth0.login({popup: true});
 *
 *       // Ok!
 *       auth0.login({popup: true}, function () { });
 *
 * @example
 *       var auth0 = new Auth0({ clientId: '...', domain: '...'});
 *
 *       // Ok!
 *       auth0.login({popup: true});
 *
 *       // Error! No callback will be executed on response_type=code
 *       auth0.login({popup: true}, function () { });
 * @private
 */

Auth0.prototype.loginWithPopup = function(options, callback) {
  var self = this;
  var query = this._buildAuthorizeQueryString([
    this._getMode(),
    options,
    { client_id: this._clientID, owp: true }]);

  var popupUrl = 'https://' + this._domain + '/authorize?' + query;
  var popupOptions = xtend(
    self._computePopupPosition({
      width: (options.popupOptions && options.popupOptions.width) || 500,
      height: (options.popupOptions && options.popupOptions.height) || 600
  }),
    options.popupOptions);

  if (!callback && self._callbackOnLocationHash) {
    throw new Error('popup mode should receive a mandatory callback');
  } else if (callback && !self._callbackOnLocationHash) {
    throw new Error('No callback supported when callbackOnLocationHash is false');
  }

  var popup = WinChan.open({
    url: popupUrl,
    relay_url: 'https://' + this._domain + '/relay.html',
    window_features: stringifyPopupSettings(popupOptions)
  }, function (err, result) {
    if (err) {
      // Winchan always returns string errors, we wrap them inside Error objects
      return callback(new Error(err), null, null, null, null);
    }

    if (result && result.id_token) {
      return self.getProfile(result.id_token, function (err, profile) {
        callback(err, profile, result.id_token, result.access_token, result.state);
      });
    }

    // Case where we've found an error
    return callback(new Error(result ? result.err : 'Something went wrong'), null, null, null, null);
  });

  popup.focus();
};

/**
 * Stringify popup options object into
 * `window.open` string options format
 *
 * @param {Object} popupOptions
 * @api private
 */

function stringifyPopupSettings(popupOptions) {
  var settings = '';

  for (var key in popupOptions) {
    settings += key + '=' + popupOptions[key] + ',';
  }

  return settings.slice(0, -1);
}

/**
 * Login with Resource Owner (RO)
 *
 * @param {Object} options
 * @param {Function} callback
 * @api public
 */

Auth0.prototype.loginWithResourceOwner = function (options, callback) {
    var self = this;
    var query = xtend(
      this._getMode(),
      options,
      {
        client_id:    this._clientID,
        username:     trim(options.username || options.email || ''),
        grant_type:   'password'
      });
  
    var endpoint = '/connect/token'; //'/oauth/ro';
  
    function enrichGetProfile(resp, callback) {
      self.getProfile(resp.id_token, function (err, profile) {
        // callback(err, profile, resp.id_token, resp.access_token, resp.state);
        callback(err, profile, resp.access_token, resp.access_token, resp.state);
      });
    }
  
    if (this._useJSONP) {
      return jsonp('https://' + this._domain + endpoint + '?' + qs.stringify(query), jsonpOpts, function (err, resp) {
        if (err) {
         
          return callback(err);
        }
        if('error' in resp) {
         
          var error = new LoginError(resp.status, resp.error);
          return callback(error);
        }
       
        enrichGetProfile(resp, callback);
      });
    }
  
    reqwest({
      url:     'https://' + this._domain + endpoint,
      method:  'post',
      type:    'json',
      data:    query,
      crossOrigin: true,
      success: function (resp) {
        enrichGetProfile(resp, callback);
      }
    }).fail(function (err) {
      var er = err;
      if (!er.status || er.status === 0) { //ie10 trick
        er = {};
        er.status = 401;
        er.responseText = {
          code: 'invalid_user_password'
        };
      }
      else {
        er.responseText = err;
      }
      var error = new LoginError(er.status, er.responseText);
      callback(error);
    });

};

/**
 * Login with Username and Password
 *
 * @param {Object} options
 * @param {Fucntion} callback
 * @api public
 */

Auth0.prototype.loginWithUsernamePassword = function (options, callback) {
  // XXX: Warning: This check is whether callback arguments are
  // fn(err) case callback.length === 1 (a redirect should be performed) vs.
  // fn(err, profile, id_token, access_token, state) callback.length > 1 (no
  // redirect should be performed)
  if (callback && callback.length > 1) {
    return this.loginWithResourceOwner(options, callback);
  }

  var self = this;
  var popup;

  if (options.popup  && !this._callbackOnLocationHash) {
    var popupOptions = stringifyPopupSettings(xtend(
                            { width: 500, height: 600 },
                            (options.popupOptions || {})));
    popup = window.open('about:blank', 'auth0_signup_popup',popupOptions);
  }

  var query = xtend(
    this._getMode(),
    options,
    {
      client_id: this._clientID,
      redirect_uri: this._callbackURL,
      username: trim(options.username || options.email || ''),
      tenant: this._domain.split('.')[0]
    });

  var endpoint = '/usernamepassword/login';

  if (this._useJSONP) {
    return jsonp('https://' + this._domain + endpoint + '?' + qs.stringify(query), jsonpOpts, function (err, resp) {
      if (err) {
        if (popup) popup.close();
        return callback(err);
      }
      if('error' in resp) {
        if (popup) popup.close();
        var error = new LoginError(resp.status, resp.error);
        return callback(error);
      }
      self._renderAndSubmitWSFedForm(options, resp.form);
    });
  }

  function return_error (error) {
    if (callback) return callback(error);
    throw error;
  }

  reqwest({
    url:     'https://' + this._domain + endpoint,
    method:  'post',
    type:    'html',
    data:    query,
    crossOrigin: true,
    success: function (resp) {
      self._renderAndSubmitWSFedForm(options, resp);
    }
  }).fail(function (err) {
    var er = err;
    if (popup) popup.close();
    if (!er.status || er.status === 0) { //ie10 trick
      er = {};
      er.status = 401;
      er.responseText = {
        code: 'invalid_user_password'
      };
    }
    var error = new LoginError(er.status, er.responseText);
    return return_error(error);
  });
};

/**
 * Get delegation token for certain addon or certain other clientId
 *
 * Examples:
 *
 *     auth0.getDelegationToken({
 *      id_token: the_id_token,
 *      api: 'auth0'
 *     }, function (err, delegationResult) {
 *        if (err) return console.log(err.message);
 *        // Do stuff with delegation result
 *        expect(delegationResult.id_token).to.exist;
 *        expect(delegationResult.token_type).to.eql('Bearer');
 *        expect(delegationResult.expires_in).to.eql(36000);
 *     })
 *
 * @param {String} targetClientId
 * @param {String} id_token
 * @param {Object} options
 * @param {Function} callback
 * @api public
 */

 Auth0.prototype.renewIdToken = function (id_token, callback) {
  this.getDelegationToken({
    id_token: id_token,
    api: 'auth0'
  }, callback);
 }

 Auth0.prototype.refreshToken = function (refresh_token, callback) {
  this.getDelegationToken({
    refresh_token: refresh_token,
    api: 'auth0'
  }, callback);
 }

Auth0.prototype.getDelegationToken = function (options, callback) {
  callback(null, options.id_token);

};

/**
 * Trigger logout redirect with
 * params from `query` object
 *
 * Examples:
 *
 *     auth0.logout();
 *     // redirects to -> 'https://yourapp.auth0.com/logout'
 *
 *     auth0.logout({returnTo: 'http://logout'});
 *     // redirects to -> 'https://yourapp.auth0.com/logout?returnTo=http://logout'
 *
 * @param {Object} query
 * @api public
 */

Auth0.prototype.logout = function (query) {
  var url = 'https://' + this._domain + '/logout';
  if (query) {
    url += '?' + qs.stringify(query);
  }
  this._redirect(url);
};

/**
 * Get single sign on Data
 *
 * Examples:
 *     auth0.getSSOData(function (err, ssoData) {
 *       if (err) return console.log(err.message);
 *       expect(ssoData.sso).to.exist;
 *     });
 *
 *     auth0.getSSOData(false, fn);
 *
 * @param {Boolean} withActiveDirectories
 * @param {Function} callback
 * @api public
 */

Auth0.prototype.getSSOData = function (withActiveDirectories, callback) {
  if (typeof withActiveDirectories === 'function') {
    callback = withActiveDirectories;
    withActiveDirectories = false;
  }

  var url = 'https://' + this._domain + '/user/ssodata';

  if (withActiveDirectories) {
    url += '?' + qs.stringify({ldaps: 1, client_id: this._clientID});
  }

  // override timeout
  var jsonpOptions = xtend({}, jsonpOpts, { timeout: 3000 });

  return jsonp(url, jsonpOptions, function (err, resp) {
    callback(null, err ? {sso:false} : resp); // Always return OK, regardless of any errors
  });
};

/**
 * Get all configured connections for a client
 *
 * Examples:
 *
 *     auth0.getConnections(function (err, conns) {
 *       if (err) return console.log(err.message);
 *       expect(conns.length).to.be.above(0);
 *       expect(conns[0].name).to.eql('Apprenda.com');
 *       expect(conns[0].strategy).to.eql('adfs');
 *       expect(conns[0].status).to.eql(false);
 *       expect(conns[0].domain).to.eql('Apprenda.com');
 *     });
 *
 * @param {Function} callback
 * @api public
 */

Auth0.prototype.getConnections = function (callback) {
  return jsonp('https://' + this._domain + '/public/api/' + this._clientID + '/connections', jsonpOpts, callback);
};

/**
 * Expose `Auth0` constructor
 */

module.exports = Auth0;
