(function() {
    'use strict';
    var commonModule = angular.module('common', []);

    commonModule.provider('commonConfig', [
        'ENVIRONMENTAL', function(ENVIRONMENTAL) {
            this.config = {
            };

            this.environmental = ENVIRONMENTAL;

            this.$get = function() {
                return {
                    config: this.config,
                    environmental: this.environmental
                };
            };
        }
    ]);
})();