module.exports = function () {
  var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : null;

  if (xhr && 'withCredentials' in xhr) {
    return false;
  }

  if ('XDomainRequest' in window && window.location.protocol === 'https:') {
    return false;
  }

  return true;
};