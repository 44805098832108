declare const angular: any;

angular.module("app")
    .controller("LogoutCtrl", (auth, $scope, $state, store, events, constants, $rootScope, ENVIRONMENTAL) => {

        let logout = () => {
            auth.signout();
            $scope.isLoading = false;
            var profile = store.get("profile");
            store.remove("profile");
            var token = store.get("token");
            store.remove("token");
            store.remove("branding");
            store.remove("tokenAPI");
            store.remove("tokenFiles");
            events.trigger(constants.SECURITY.EVENTS.LOGGEDOUT);
            $scope.progress.complete();

            var theme = store.get("theme");
            var themeUrlPart = '';

            if (theme && theme !== null) {
                themeUrlPart = "/login?theme=" + theme;
            }
            var url = "https://" + ENVIRONMENTAL.AUTH0.DOMAIN + "/connect/endsession" +
                "?id_token_hint=" + token + 
                "&post_logout_redirect_uri=" + ENVIRONMENTAL.AUTH0.CALLBACKURL + 
                themeUrlPart;

            window.location.replace(url);
        };

        logout();

        // RootScope event handler for any time we broadcast the forbidden event, log the user out.
        $rootScope.$on("auth:forbidden", () => {
            logout();
        });
    });
