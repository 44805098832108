(function () {
    'use strict';

    var module = angular.module('bf.services.dataLayerService', []);

    module.service('dataLayerService', ['$q', 'constants', 'ENVIRONMENTAL', 'dataStoreService', 
        function ($q, constants, ENVIRONMENTAL, dataStoreService) {
            var apiSection = '/datalayer/push/';

            function doPushEvent(dataLayerEvent) {
                var uri = ENVIRONMENTAL.BASEURLS.API + apiSection;
                
                return dataStoreService.addResource(uri, dataLayerEvent);
            }

            return {
                pushEvent: doPushEvent
            };
        }
    ]);
})();