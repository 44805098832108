// Obviously this requires reworking - for now, it's a simple means to DRY up things between the user site and the admin site.
// Vendor scripts - these should be coming from NPM unless there's a good reason why they can't.

// TODO: Resolve dependency on jQuery globals
require("imports-loader?windowTemp=>window!expose-loader?window.$!expose-loader?window.jQuery!jquery");
require("imports-loader?windowTemp=>window&windowTemp.jQuery=jquery!angular");
require("angular-ui-bootstrap/ui-bootstrap-tpls");
require('./VendorScripts/auth0.js');

require('./VendorScripts/lib/index.js');
require('./VendorScripts/lib/base64_url_decode.js');

require('./VendorScripts/lib/assert_required.js');
require('./VendorScripts/lib/json_parse.js');
require('./VendorScripts/lib/LoginError.js');
require('./VendorScripts/lib/use_jsonp.js');

// 3.0.2 _does_ exist on NPM, however it requires Bower which seems like a step in the wrong direction
require("./VendorScripts/auth0-angular-3.0.2.js");

// TODO: Resolve dependency on moment global
require("expose-loader?moment!moment");

require("imports-loader?define=>false!angular-moment");

// TODO: Resolve dependency on underscore global
const _ = require('expose-loader?_!underscore');
const underscoreString = require('underscore.string');
_.mixin(underscoreString.exports());

require('@progress/kendo-ui/js/kendo.all');
require('@progress/kendo-ui/js/cultures/kendo.culture.en-AU');
require("@progress/kendo-ui/css/web/kendo.common-bootstrap.min.css");

require("imports-loader?jQuery=jquery!./VendorScripts/bootstrap.min.js");
require("angular-jwt");
require("angular-storage");
require("imports-loader?define=>false,module=>false!@flowjs/ng-flow/dist/ng-flow-standalone.js");
require("angular-ui-router");

// TODO: Why isn't this coming from NPM?
require("./VendorScripts/ngProgress.min.js");

require("angular-loading-pane/loading-pane.js");
require("angular-loading-pane/loading-pane-views.js");
require("angular-loading-pane/loading-pane.css");

// END of vendor scripts